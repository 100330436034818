<template>
  <component :is="configData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="configData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching country data
      </h4>
      <div class="alert-body">
        No country found with this country id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'countries'}"
        >
          Countries List
        </b-link>
        for other countries.
      </div>
    </b-alert>
    <b-form @submit.prevent="onUpdate">
      <template v-for="(config , index) in configData">
<!--        <template v-if="config.field_type === 'text'">-->
<!--          <b-col style="display: inline-block" md="6">-->
<!--            <b-form-group-->
<!--                :label="config.label_en"-->
<!--                :label-for="index+'_en'"-->
<!--            >-->
<!--              <b-form-input-->
<!--                  v-model="config.value_en"-->
<!--                  :id="index+'_en'"-->
<!--                  type="text"-->
<!--              />-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--          <b-col style="display: inline-block" md="6">-->
<!--            <b-form-group-->
<!--                :label="config.label_ar"-->
<!--                :label-for="index+'_ar'"-->
<!--            >-->
<!--              <b-form-input-->
<!--                  v-model="config.value_ar"-->
<!--                  :id="index+'_ar'"-->
<!--                  type="text"-->
<!--              />-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--        </template>-->
<!--        <template v-if="config.field_type === 'text_editor'">-->
<!--          <b-col style="display: inline-block" md="6">-->
<!--            <b-form-group-->
<!--                :label="config.label_en"-->
<!--                :label-for="index+'_en'"-->
<!--            >-->
<!--            <quill-editor-->
<!--                :id="index+'_en'"-->
<!--                v-model="config.value_en"-->
<!--                :options="snowOption"-->
<!--            />-->
<!--            </b-form-group>-->

<!--          </b-col>-->
<!--          <b-col style="display: inline-block" md="6">-->
<!--            <b-form-group-->
<!--                :label="config.label_ar"-->
<!--                :label-for="index+'_ar'"-->
<!--            >-->
<!--              <quill-editor-->
<!--                  :id="index+'_ar'"-->
<!--                  v-model="config.value_ar"-->
<!--                  :options="snowOption"-->
<!--              />-->
<!--            </b-form-group>-->

<!--          </b-col>-->
<!--        </template>-->
        <template v-if="config.field_type === 'number'">
          <b-col md="6" style="display: inline-block">
            <b-form-group
                :label="config.label_en"
                :label-for="'config_type'"
            >
              <cleave
                  v-model="config.value_en"
                  id="mc-android-version"
                  step=".1"
                  min="1"
                  class="form-control"
                  :raw="false"
                  :options="mask.number"
              />
            </b-form-group>

          </b-col>
        </template>
      </template>
      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Cleave from 'vue-cleave-component'
import configStoreModule from '../configStoreModule'
import {BAlert, BButton, BCard, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BLink, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
export default {
  components: {
    BCard,
    Cleave,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    quillEditor,
    BButton
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      mask: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'wan',
          delimiter: '',
          numericOnly: true,
        },
      },
    }
  },
  setup() {
    const blankConfigData = {
      android_version: '',
      ios_version: '',
    }
    const countryFlagId = ref(null);
    const toast = useToast()

    const configData = ref(JSON.parse(JSON.stringify(blankConfigData)))
    const snowOption = ref({
      theme: 'snow',
    })

    const CONFIG_APP_STORE_MODULE_NAME = 'config'

    // Register module
    if (!store.hasModule(CONFIG_APP_STORE_MODULE_NAME)) store.registerModule(CONFIG_APP_STORE_MODULE_NAME, configStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONFIG_APP_STORE_MODULE_NAME)) store.unregisterModule(CONFIG_APP_STORE_MODULE_NAME)
    })
    store.dispatch('config/fetchConfig', {id: router.currentRoute.params.id})
        .then(response => {
          configData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            configData.value = undefined
          }
        })

    const onUpdate = () => {
      console.log(configData.value)
      store.dispatch('config/updateConfig', {
        data: configData.value
      })
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Config Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {

          })
    }
    return {
      onUpdate,
      configData,
      snowOption,
      countryFlagId
    }
  },
}
</script>

<style>

</style>
