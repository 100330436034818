import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchConfig(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/config', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateConfig(ctx, {data}) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/config/', data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
